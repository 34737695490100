<template>
  <div>
    <Loading v-show="showPdf"/>
    <div id="app"><div id="demo"></div></div>
  </div>
</template>
 
<script>
import Pdfh5 from 'pdfh5';
import axios from 'axios';
import { Toast } from 'vant';
import Loading from "@/components/Loading";
  export default {
    components: {
      Loading
    },
    data () {
      return {
				pdfh5: null,
        contractNo: this.$route.query.contractNo,
        showPdf:true
       }
    },
    created(){
      this.getPdf()
    },
    mounted(){
      let that = this;
      sessionStorage.setItem('key', 'settlementQuery2');
      window.addEventListener("popstate", function(e) {  //回调函数中实现需要的功能
        if(sessionStorage.getItem('key') == 'settlementQuery2') {
          that.$router.push({ path: '/settlementQuery' });
        }
      }, false);
　　},
    methods: {
      getPdf(){
        // 有时PDF文件地址会出现跨域的情况,这里最好处理一下
        axios.post('/wxapi/settlefilepage',{
          contract_number:this.contractNo
        }, {
          responseType: 'arraybuffer'
        })
        .then(res => {
          this.pdfh5 = new Pdfh5('#demo', {
            data: res.data
          });
          this.showPdf = false
        });
      }
    }
  }
</script>

<style>
/* @import '@/lib/pdfh5.css'; */
* {
  padding: 0;
  margin: 0;
}
html,
body,
#app {
  width: 100%;
  height: 100%;
}
</style>
